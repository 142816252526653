import React from 'react';
import {useState} from 'react';

import PageLayout from '../components/PageLayout';
import useFetch from 'react-fetch-hook';

import styled from 'styled-components';

import SEO from '../components/seo';

const pad = d => (d < 10 ? '0' + d : d);

const formatDate = d => {
  return `${pad(d.getHours())}:${pad(d.getMinutes())}`;
};

const formatDifference = (a, b) => {
  if (!a || !b) {
    return `Free until the end of the day`;
  }

  a = new Date(a);
  b = new Date(b);

  const ms = b.getTime() - a.getTime();
  const minutes = Math.round(ms / 1000 / 60);

  if (minutes > 60 * 12) {
    return `Free until the end of the day`;
  }

  return `Free until ${b.getHours()}:${pad(b.getMinutes())}`;
};

function isFavorite(name) {
  return name == 'A 91.366';
}

function Room({name}) {
  return (
    <span
      style={{
        fontWeight: isFavorite(name) ? 'bold' : 'normal'
      }}
    >
      {name.replace(/^200(\w)\s0/, '$1')}
    </span>
  );
}

const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 0;
  }
`;

function join(array, sep) {
  if (array.length > 0)
    return array.reduce((prev, curr) => [...prev, ', ', curr]);
  return null;
}

function Table({data}) {
  const [offset, setOffset] = useState(0);

  const now = new Date();
  // now.setHours(11)
  // now.setDate(now.getDate() + 5)

  now.setMinutes(now.getMinutes() + offset);

  const free = Object.keys(data)
    .filter(room =>
      data[room].every(
        ({start, end}) => now < new Date(start) || new Date(end) < now
      )
    )
    .map(room => {
      const next = data[room].find(({start}) => new Date(start) > now);

      return {
        room: room,
        textDelta: formatDifference(now, next && next.start),
        delta: !next ? Infinity : new Date(next.start) - new Date(now)
      };
    });

  const used = Object.keys(data).filter(room =>
    data[room].some(
      ({start, end}) => new Date(start) < now && now < new Date(end)
    )
  );

  const deltas = Array.from(new Set(free.map(p => p.textDelta)))
    .map(textDelta => free.find(p => p.textDelta == textDelta))
    .sort((a, b) => b.delta - a.delta);

  const freeRows = deltas.map(({textDelta}) => {
    const rooms = free.filter(p => p.textDelta == textDelta);
    return (
      <li>
        <b style={{marginRight: '0.5em'}}>
          {textDelta}
        </b>
        {join(rooms.map(d => <Room name={d.room} />), ', ')}
      </li>
    );
  });

  return (
    <div style={{marginTop: '1em'}}>
      <div
        style={{
          display: 'flex',
          marginBottom: '1em'
        }}
      >
        {[0, 10, 20, 30].map(o =>
          <a
            style={{
              fontWeight: offset == o ? 'bold' : 'normal',
              cursor: 'pointer',
              padding: '0 0.5em',
              paddingLeft: o == 0 ? '0' : '0.5em'
            }}
            onClick={() => setOffset(o)}
          >
            {o == 0 ? 'Now' : `In ${o} min.`}
          </a>
        )}
        <span
          style={{
            textAlign: 'right',
            flexGrow: '1'
          }}
        >
          {formatDate(new Date(now))}
        </span>
      </div>
      <List>
        {freeRows}
        <li>
          <b>Not free</b> {join(used.map(n => <Room name={n} />), ', ')}
        </li>
      </List>
    </div>
  );
}

function FindRoom() {
  const {isLoading, data} = useFetch('/.netlify/functions/kul-room');

  if (isLoading) {
    return (
      <PageLayout>
        <div>
          <h1>Searching for a room?</h1>
          <p> Loading ... </p>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <SEO title="Find a room" />
      <div>
        <h1>Searching for a room?</h1>
        {<Table data={data} />}
      </div>
    </PageLayout>
  );
}

export default FindRoom;
